<template>
  <BaseDialog :model-value="show" size-class="!max-w-xl min-h-[40rem]" @update:model-value="$emits('close')">
    <template #header>
      <span class="text-sm" v-if="dashboard">Edit Dashboard</span>
      <span class="text-sm" v-else>Create New Dashboard</span>
    </template>
    <template #default>
      <div v-if="fetchingDashboard" class="mt-40 flex items-center justify-center">
        <BaseLoader />
      </div>
      <div v-else class="space-y-4">
        <div class="flex flex-col gap-1">
          <label for="title" class="text-xs text-gray-400">Title <span class="text-red-500">*</span></label>
          <BaseTextbox placeholder="Title of Dashboard" id="title" v-model.trim="editableDashboard.title" />
          <span class="text-xs text-red-400"> {{ titleError }}</span>
        </div>
        <div class="flex flex-col gap-1">
          <label for="description" class="text-xs text-gray-400"> Description(optional) </label>
          <BaseTextarea
            rows="4"
            placeholder="Description of Dashboard"
            name="description"
            v-model="editableDashboard.description"
          />
        </div>
        <div class="flex flex-col gap-1">
          <label for="description" class="text-xs text-gray-400"> Tag (optional) </label>
          <BaseSelect
            v-model="selectedTags"
            :options="tagOptions"
            button-width-class="w-full"
            dropdown-width-class="w-full"
            placeholder="Select tags"
            multiple
            searchable
            appendable
            @action:search="searchedTag = $event"
            @append:option="addSearchedOption"
          >
            <template #footer v-if="searchedTag">
              <div class="tiedy-p-2">+ Add "{{ searchedTag }}" as a tag</div>
            </template>
          </BaseSelect>
        </div>
        <div class="relative space-y-2">
          <div class="flex items-center gap-1">
            <label for="description" class="text-xs text-gray-400">Share Dashboard with Your Team (optional)</label>
          </div>

          <BaseSelect
            button-width-class="w-full"
            dropdown-width-class="w-[526px]"
            placeholder="Add a Teammate"
            searchable
            portal
            :options="teammates"
            :model-value="null"
            @update:model-value="addPermissionedTeammate"
          />
          <div v-if="permissionedTeammates.length > 0" class="flex flex-col gap-1">
            <BaseDivider />
            <div class="flex h-32 flex-col gap-2 overflow-y-auto">
              <div
                v-for="(teammate, index) in permissionedTeammates"
                :key="index"
                class="flex flex-row items-center justify-between"
              >
                <div class="text-xs leading-none">
                  {{ teammate.label }}
                </div>
                <div class="flex flex-row items-center gap-2">
                  <BaseSelect
                    v-model="teammate.permission"
                    size="sm"
                    :options="permissions"
                    portal
                    dropdown-width-class="w-32"
                    button-width-class="w-32"
                  />
                  <BaseButton size="xxs" type="ghost" @press="removePermissionedTeammate(teammate.id)">
                    <XMarkIcon class="float-right h-3.5 w-3.5 text-red-700" />
                  </BaseButton>
                </div>
              </div>
            </div>
            <BaseDivider />
            <div class="mt-2 space-y-4">
              <div class="flex flex-1 flex-col gap-1">
                <span class="text-xs text-gray-100">Subscribers</span>
                <p class="text-xs text-gray-400">
                  Can only view the items in a dashboard. Cannot change the dashboard.
                </p>
              </div>
              <div class="flex flex-1 flex-col gap-1">
                <span class="text-xs text-gray-100">Editors</span>
                <p class="text-xs text-gray-400">
                  Can view, add, and remove items in a dashboard. Can also share and edit the dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="!fetchingDashboard" #footer>
      <div class="flex w-full justify-between space-x-2">
        <div>
          <BaseButton v-if="canDelete" type="danger" @press="destroy">Delete</BaseButton>
        </div>
        <div class="flex space-x-2">
          <BaseButton type="secondary" @press="$emits('close')" :disabled="buttonBusy">Cancel</BaseButton>
          <BaseButton
            v-if="editableDashboard.id"
            @press="update"
            :busy="buttonBusy"
            :disabled="!readyToAdd || duplicateTitle || buttonBusy"
          >
            Update
          </BaseButton>
          <BaseButton v-else :busy="buttonBusy" @press="create" :disabled="!readyToAdd || duplicateTitle || buttonBusy">
            Create
          </BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, inject, nextTick, onBeforeMount, onMounted, onUpdated, ref } from 'vue';
import useHttp from '@/composeables/http';
import { useDashboardApi } from '@/composeables/dashboard-api';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const $store = useStore();
const $http = useHttp();
const $emits = defineEmits(['close', 'update', 'add']);
const $dashboardApi = useDashboardApi();

const props = defineProps({
  show: { type: Boolean, default: false },
  dashboard: {
    type: Object,
    default: () => {}
  },
  fetchDashboard: { type: Boolean, default: false }
});

//EDITABLE DASHBOARD
const existingDashboards = inject('user-dashboards', []);
const editableDashboard = ref({});
const fetchingDashboard = ref(false);
const readyToAdd = computed(() => {
  return editableDashboard.value.title.trim().length > 0;
});
const duplicateTitle = computed(() => {
  if (editableDashboard.value.id > 0) {
    const otherDashboards = existingDashboards
      .filter(x => x.id != editableDashboard.value.id)
      .map(x => x.title.toLowerCase());
    return otherDashboards.some(x => x == editableDashboard.value.title.toLowerCase());
  }
  return false;
});
const titleError = computed(() => {
  if (duplicateTitle.value) {
    return 'Dashboard with this title exists already.';
  }
  return null;
});
const canDelete = computed(() => {
  const currentUserId = $store.getters.session.user.id;
  return props.dashboard && props.dashboard.user_id === currentUserId;
});

async function getDashboard() {
  fetchingDashboard.value = true;
  let data = await $dashboardApi.fetchActiveDashboard(props.dashboard.id);
  editableDashboard.value = data.dashboard;
  fetchingDashboard.value = false;
}

onBeforeMount(async () => {
  if (props.dashboard && !props.fetchDashboard) {
    editableDashboard.value = { ...props.dashboard };
  } else if (props.fetchDashboard) {
    await getDashboard();
  }
});

//TEAM MATES
const teammates = ref([]);
const selectedTeamMates = ref([]);
const permissionedTeammates = ref([]);
const permissions = [
  { id: 'subscriber', label: 'Subscriber' },
  { id: 'editor', label: 'Editor' }
];

async function getTeammates() {
  const response = await $http.get('/teammates');
  teammates.value = response.data.map(u => {
    return {
      id: u.id,
      label: u.email
    };
  });
}

function addPermissionedTeammate(id) {
  if (permissionedTeammates.value.find(t => t.id === id)) return;

  permissionedTeammates.value.push({
    ...teammates.value.find(t => t.id === id),
    permission: 'subscriber'
  });
}

function removePermissionedTeammate(id) {
  permissionedTeammates.value = permissionedTeammates.value.filter(t => t.id != id);
}

function setPermissionedTeammates() {
  editableDashboard.value.permissioned_users?.forEach(m => {
    permissionedTeammates.value.push({
      id: m.user_id,
      label: m.email,
      permission: m.can_read && m.can_write ? 'editor' : m.can_read && !m.can_write ? 'subscriber' : ''
    });
  });
}

onUpdated(() => {
  setPermissionedTeammates();
});
onMounted(() => {
  getTeammates();
  if (editableDashboard.value) {
    setPermissionedTeammates();
  }
});

//DASHBOARD CRUDS
const buttonBusy = ref(false);

async function create() {
  try {
    buttonBusy.value = true;
    editableDashboard.value.tags = tagOptions.value.filter(i => selectedTags.value.includes(i.id));
    editableDashboard.value.subscribers = permissionedTeammates.value.filter(x => x.permission === 'subscriber');
    editableDashboard.value.maintainers = permissionedTeammates.value.filter(x => x.permission === 'editor');
    const response = await $http.post('/dashboard', {
      dashboard: editableDashboard.value,
      maintainers: editableDashboard.value.maintainers,
      subscribers: editableDashboard.value.subscribers
    });
    buttonBusy.value = false;
    $emits('add', response.data.data);
  } catch {
    // do nothing
    buttonBusy.value = false;
  }
}

async function update() {
  try {
    buttonBusy.value = true;
    editableDashboard.value.tags = tagOptions.value.filter(i => selectedTags.value.includes(i.id));
    editableDashboard.value.subscribers = permissionedTeammates.value.filter(x => x.permission === 'subscriber');
    editableDashboard.value.maintainers = permissionedTeammates.value.filter(x => x.permission === 'editor');
    const response = await $http.patch(`/dashboard/${editableDashboard.value.id}`, {
      dashboard: editableDashboard.value,
      maintainers: editableDashboard.value.maintainers,
      subscribers: editableDashboard.value.subscribers
    });
    buttonBusy.value = false;
    $emits('update', response.data.data);
  } catch {
    buttonBusy.value = false;
  }
}

function destroy() {
  $store.dispatch('confirm', 'Please confirm you want to delete this dashboard?').then(() => {
    $http.delete(`/dashboard/${editableDashboard.value.id}`).then(() => {
      //do not replace with Inertia
      location.reload();
    });
  });
}

//TAGS
const tagOptions = ref([]);
const selectedTags = ref([]);
const searchedTag = ref(null);

async function getDashboardTags() {
  const response = await $dashboardApi.getAllTags();
  tagOptions.value =
    response?.map(x => {
      return { id: x.id, label: x.name };
    }) || [];
  if (!fetchingDashboard.value) {
    tagOptions.value.forEach(x => {
      const hasTag = editableDashboard.value.tags.filter(z => z.event_value == x.id);
      if (hasTag.length > 0) {
        selectedTags.value.push(x.id);
      }
    });
  }
}

function addSearchedOption() {
  const exists = tagOptions.value.find(x => x.label === searchedTag.value);
  if (!exists) {
    const option = { id: Math.random(), label: searchedTag.value };
    tagOptions.value.push(option);
    nextTick(() => {
      selectedTags.value.push(option.id);
    });
  }
}
onMounted(() => {
  nextTick(() => {
    getDashboardTags();
  });
});
</script>
